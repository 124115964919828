import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../../core/security/authentication.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map, Observable, of } from 'rxjs';

const MAPPING_TAB = 'mapping';
const PROGRAM_CONFIG_TAB = 'program-config';
const DATA_CALENDAR_TAB = 'data-calendar';
const BUDGET_TAB = 'budget';

@Component({
  selector: 'rtpca-navbar',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, TranslateModule],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  public connectedUser = this.authenticationService.connectedUser;
  tabName$:Observable<string> = of('mapping');
  availableTabs = [
    { label: MAPPING_TAB, route: '/mapping' },
    { label: PROGRAM_CONFIG_TAB, route: '/program-config' },
    { label: BUDGET_TAB, route: '/budget' },
    { label: DATA_CALENDAR_TAB, route: '/data-calendar' }
  ];
  selectedTab = MAPPING_TAB;
  activeTabIndex = 0;
  currentLang: string;
  constructor(
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    const savedLanguage = localStorage.getItem('language') ?? 'en';
    this.translateService.use(savedLanguage);
    this.currentLang = savedLanguage;
  }

  ngOnInit() {
    this.tabName$ = this.getTabName$();
  }

  getTabName$(): Observable<string> {
    return this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
            this.activeTabIndex = this.availableTabs.findIndex(
              (tab) => tab.route === (event.urlAfterRedirects)
            );
          return this.availableTabs[this.activeTabIndex].label;
        })
      );
  }
  
  public logout() {
    this.authenticationService.logout();
  }

  onChangeTab(tab: string): void {
    this.selectedTab = tab;
  }
  switchLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
  }
}

<nav class="navbar navbar-expand-sm flex-column">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/images/te_logo.png" alt="Logo TotalEnergies" />
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">RTPCA</span>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item ms-md-4">
            <div class="dropdown">
              <span class="navbar-text text-uppercase">{{
                (connectedUser | async)?.username
              }}</span>
              <button
                class="btn btn-outline-primary btn-circle"
                id="profile"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="material-icons-outlined">account_circle</span>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="profile"
              >
                <li>
                  <a class="dropdown-item" href="#" (click)="logout()"
                    >Logout</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item d-none d-xl-block">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="lang"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ currentLang | uppercase }}
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="lang"
              >
                <li>
                  <a
                    (click)="switchLanguage('en')"
                    class="dropdown-item"
                    href="#"
                    >English</a
                  >
                </li>
                <li>
                  <a
                    (click)="switchLanguage('fr')"
                    class="dropdown-item"
                    href="#"
                    >French</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <hr class="separator" />
      <div class="container-fluid">
        <ul class="nav nav-tabs">
          @for( tab of availableTabs; track tab){
          <li>
            <a class="nav-link" aria-current="page" [routerLink]="tab.route" [ngClass]="{ active: (tabName$ | async) === tab.label }"
              (click)="onChangeTab(tab.label)">{{ tab.label | translate }}</a>
          </li>
          }
        </ul>
      </div>
    </div>
  </div>
</nav>
